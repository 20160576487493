<template>
    <nav class="navbar fixed-top navbar-expand-lg" :class="{'mobile-opaque': mobileOpaque}">
        <div class="container">
            <router-link :to="{ name: 'welcome' }" class="navbar-brand">
                <img :src="`${assetsUrl}ts-logo-plain.svg`" alt="Truestock">
            </router-link>
            <div id="nav-main-search" class="d-inline-block">
                <form class="form-inline" @submit.stop.prevent="doSearch">
                    <div class="input-group nav-search pad-left-spacer">
                        <input v-model="searchTerm" type="text" class="form-control" placeholder="Search our exclusive Royalty-free library">
                        <div class="input-group-prepend">
                            <div v-if="locale === 'nz'" id="search-type-dropdown" class="dropdown">
                                <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                                    <span class="search-icon-txt">{{ searchType }}</span>
                                    <span class="search-icon">
                                        <template v-if="searchType === 'Images + Videos'">
                                            <b-icon-camera-fill/> + <b-icon-camera-video-fill/>
                                        </template>
                                        <b-icon-camera-fill v-else-if="searchType === 'Images'"/>
                                        <b-icon-camera-video-fill v-else-if="searchType === 'Videos'"/>
                                    </span>
                                    <b-icon-chevron-down/>
                                </button>
                                <ul class="dropdown-menu">
                                    <li :class="{'active': searchType === 'Images'}" @click="selectType('Images')"><a>Images</a></li>
                                    <li :class="{'active': searchType === 'Videos'}" @click="selectType('Videos')"><a>Videos</a></li>
                                    <li :class="{'active': searchType === 'Images + Videos'}" @click="selectType('Images + Videos')"><a>Images + Videos</a></li>
                                </ul>
                            </div>
                            <div v-if="locale === 'aus'" id="search-location-dropdown" class="dropdown">
                                <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                                    <span class="search-icon-txt">{{ searchState }}</span>
                                    <b-icon-chevron-down/>
                                </button>
                                <ul class="dropdown-menu">
                                    <li :class="{'active': searchState === 'VIC'}" @click="selectState('VIC')"><a>VIC</a></li>
                                    <li :class="{'active': searchState === 'QLD'}" @click="selectState('QLD')"><a>QLD</a></li>
                                    <li :class="{'active': searchState === 'NSW'}" @click="selectState('NSW')"><a>NSW</a></li>
                                    <li :class="{'active': searchState === 'WA'}" @click="selectState('WA')"><a>WA</a></li>
                                    <li :class="{'active': searchState === 'SA'}" @click="selectState('SA')"><a>SA</a></li>
                                    <li :class="{'active': searchState === 'TAS'}" @click="selectState('TAS')"><a>TAS</a></li>
                                    <li :class="{'active': searchState === 'ALL AUS'}" @click="selectState('ALL AUS')"><a>ALL AUS</a></li>
                                </ul>
                            </div>
                            <input type="submit" value="Search" class="btn btn-default">
                        </div>
                    </div>
                </form>
            </div>
            <div id="toggleParent" class="text-right d-inline-block float-right">
                <button class="navbar-toggler btn-default search-toggler d-none" type="button" data-toggle="collapse" data-target="#searchToggler" aria-controls="searchToggler" aria-expanded="false">
                    <b-icon-search/>
                </button>
                <nuxt-link class="navbar-toggler btn-default d-inline-block d-lg-none cart" data-toggle="collapse" data-target=".navbar-collapse.show" :class="{hasItems:cartCount > 0}" :to="{ name: 'cart' }">
                    <b-icon-cart/>
                </nuxt-link>
                <button class="navbar-toggler btn-default burger" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false">
                    <b-icon-list/>
                </button>
                <div class="accordion-group">
                    <div id="searchToggler" data-parent="#toggleParent" class="collapse navbar-collapse navbar-toggler-content">
                        <div class="search-wrap d-flex d-lg-none text-left pl-4 pr-4 mt-4">
                            <form id="mobileSearchForm" @submit.stop.prevent="doSearch">
                                <div class="form-group">
                                    <div id="mobile-search-inputs" class="input-group">
                                        <input v-model="searchTerm" type="text" class="form-control" placeholder="Search our exclusive Royalty-free library">
                                        <b-button-group v-if="locale === 'nz'">
                                            <b-button :class="{'active': searchType === 'Images + Videos'}" @click="selectType('Images + Videos')">Images + Videos</b-button>
                                            <b-button :class="{'active': searchType === 'Images'}" @click="selectType('Images')">Images</b-button>
                                            <b-button :class="{'active': searchType === 'Videos'}" @click="selectType('Videos')">Videos</b-button>
                                        </b-button-group>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group-prepend">
                                        <button type="submit" class="btn btn-primary" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="navbarToggler" data-parent="#toggleParent" class="collapse navbar-collapse navbar-toggler-content">
                        <ul class="navbar-nav mobile ml-auto d-flex d-lg-none text-left">
                            <li class="nav-item">
                                <nuxt-link :to="{ name: 'cart' }" class="nav-link cart-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    View Cart ({{ cartCount }})
                                </nuxt-link>
                            </li>
                            <li class="nav-item">
                                <nuxt-link v-if="user" :to="{ name: 'collections' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    Collections
                                </nuxt-link>
                                <a v-else class="nav-link pointer" data-toggle="collapse" data-target=".navbar-collapse.show" @click.prevent="showAuthModal(null, 'redirectToCollections')">Collections</a>
                            </li>
                            <template v-if="user">
                                <li class="nav-item">
                                    <nuxt-link :to="{ name: 'account.profile' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        Profile
                                    </nuxt-link>
                                </li>
                                <li class="nav-item">
                                    <nuxt-link :to="{ name: 'account.billing' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        Billing
                                    </nuxt-link>
                                </li>
                                <li class="nav-item">
                                    <nuxt-link :to="{ name: 'account.purchases' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        Purchases
                                    </nuxt-link>
                                </li>
                                <li v-if="user.account_type === 'business'" class="nav-item">
                                    <nuxt-link :to="{ name: 'account.team' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        My Team
                                    </nuxt-link>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" @click.prevent="logout">Logout</a>
                                </li>
                            </template>
                            <template v-else>
                                <li class="nav-item">
                                    <a :href="$router.resolve({ name: 'login' }).href" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" @click.prevent="showAuthModal">Log In / Register</a>
                                </li>
                            </template>
                            <li v-if="locale === 'nz'" class="nav-item">
                                <nuxt-link :to="{ name: 'tailored' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Tailored Content</nuxt-link>
                            </li>
                            <li class="nav-item">
                                <nuxt-link :to="{ name: 'pre-purchase' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Pricing &amp; Discounts</nuxt-link>
                            </li>
                            <li class="nav-item">
                                <nuxt-link :to="{ name: 'apply-to-join' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    Become a contributor
                                </nuxt-link>
                            </li>
                            <li class="nav-item">
                                <nuxt-link :to="{ name: 'about' }" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    About Truestock
                                </nuxt-link>
                            </li>
                            <li class="nav-item">
                                <b-link v-b-modal.contact-modal class="nav-link">Contact Us</b-link>
                            </li>
                        </ul>
                        <ul class="navbar-nav ml-auto d-none d-lg-flex">
                            <li v-if="locale === 'nz'" class="nav-item d-none" :class="{'d-inline-block':!user, 'tailored-link':user, 'tailored-unauth-link':!user}">
                                <nuxt-link :to="{ name: 'tailored' }" class="nav-link">Tailored Content</nuxt-link>
                            </li>
                            <li class="nav-item d-none" :class="{'d-inline-block':!user}">
                                <nuxt-link :to="{ name: 'pre-purchase' }" class="nav-link">Pricing &amp; Discounts</nuxt-link>
                            </li>
                            <li v-if="!user" class="nav-item contribute-link">
                                <nuxt-link :to="{ name: 'apply-to-join' }" class="nav-link">Contribute</nuxt-link>
                            </li>
                            <li v-if="user && user.credit_balance !== null && user.discount_pcnt !== null" class="nav-item ml-1 mr-2">
                                <nuxt-link :to="{ name: 'pre-purchase' }" class="nav-link credits-link">
                                    Credit: <span>{{ moneyOptionalDecimal(user.credit_balance) }}</span> | Discount: <span>{{ user.discount_pcnt }}%</span>
                                </nuxt-link>
                            </li>
                            <li class="nav-item">
                                <nuxt-link v-if="user" class="nav-link cart-link cart icon" data-toggle="collapse" data-target=".navbar-collapse.show" :to="{ name: 'collections' }">
                                    <b-icon-images/>
                                </nuxt-link>
                                <a v-else class="nav-link cart-link cart icon pointer" data-toggle="collapse" data-target=".navbar-collapse.show" @click.prevent="showAuthModal(null, 'redirectToCollections')">
                                    <b-icon-images/>
                                </a>
                            </li>
                            <li class="nav-item">
                                <nuxt-link class="nav-link cart-link cart icon" data-toggle="collapse" data-target=".navbar-collapse.show" :class="{'btn-color':cartCount > 0}" :to="{ name: 'cart' }">
                                    <b-icon-cart-check-fill v-if="cartCount > 0"/>
                                    <b-icon-cart v-else/>
                                </nuxt-link>
                            </li>

                            <!-- Authenticated -->
                            <li v-show="user" class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle text-dark" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <b-icon-list/>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <template v-if="user">
                                        <span class="dropdown-item pl-3 pr-5">
                                            <template v-if="locale === 'nz'">Kia ora</template>
                                            <template v-else>Hi</template>
                                            {{ user.first_name }}
                                        </span>
                                        <div class="dropdown-divider"/>
                                        <router-link :to="{ name: 'account.profile' }" class="dropdown-item pl-3 pr-5">
                                            Account
                                        </router-link>
                                        <router-link :to="{ name: 'account.billing' }" class="dropdown-item pl-3 pr-5">
                                            Billing
                                        </router-link>
                                        <router-link :to="{ name: 'account.purchases' }" class="dropdown-item pl-3 pr-5">
                                            Purchases
                                        </router-link>
                                        <router-link v-if="user.account_type === 'business'" :to="{ name: 'account.team' }" class="dropdown-item pl-3 pr-5">
                                            My Team
                                        </router-link>
                                        <div class="dropdown-divider"/>
                                        <router-link v-if="locale === 'nz'" :to="{ name: 'tailored' }" class="dropdown-item pl-3 pr-5">Tailored Content</router-link>
                                        <router-link :to="{ name: 'pre-purchase' }" class="dropdown-item pl-3 pr-5">Pricing &amp; Discounts</router-link>
                                        <router-link :to="{ name: 'apply-to-join' }" class="dropdown-item pl-3 pr-5">Contribute</router-link>
                                        <div class="dropdown-divider"/>
                                        <a class="dropdown-item pl-3 pr-5" href="#" @click.prevent="logout">Logout</a>
                                    </template>
                                </div>
                            </li>

                            <!-- Guest -->
                            <template v-if="!user">
                                <li class="nav-item">
                                    <a :href="$router.resolve({ name: 'login' }).href" class="nav-link icon auth" @click.prevent="showAuthModal">
                                        <b-icon-person/>
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <auth-modal ref="authModal"/>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthModal from '~/components/auth/AuthModal'

export default {
    components: {
        AuthModal
    },
    data: () => ({
        appName: process.env.appName,
        searchTerm: '',
        searchType: 'Images + Videos',
        searchState: 'ALL AUS',
        mobileOpaque: false
    }),
    computed: mapGetters({
        industries: 'data/industries',
        user: 'auth/user',
        cartCount: 'cart/itemCount',
        priceTiers: 'data/priceTiers'
    }),
    watch: {
        $route (to, from) {
            if (to.query.term) {
                this.searchTerm = to.query.term
            }
        }
    },
    async created () {
        if (this.user) {
            await this.$store.dispatch('auth/fetchUser')
        }
    },
    mounted () {
        this.searchTerm = this.$route.query.term

        if (this.$route.query.type === 'image') {
            this.searchType = 'Images'
        } else if (this.$route.query.type === 'video') {
            this.searchType = 'Videos'
        }

        if (this.$route.query.state) {
            this.searchState = this.$route.query.state
        }

        this.$nuxt.$on('mobileOpaque', this.setMobileOpaque)
        this.$nuxt.$on('showAuthModal', this.showAuthModalEvent)
        this.$nuxt.$on('redirectToCollections', this.collectionsRedirect)
    },
    beforeDestroy () {
        this.$nuxt.$off('showAuthModal', this.showAuthModalEvent)
        this.$nuxt.$off('showAuthModal', this.showAuthModalEvent)
        this.$nuxt.$off('redirectToCollections', this.collectionsRedirect)
    },
    methods: {
        setMobileOpaque (shouldBeOpaque) {
            this.mobileOpaque = shouldBeOpaque
        },
        collectionsRedirect () {
            this.$router.push({ name: 'collections' }).catch(() => {
            })
        },
        showAuthModalEvent (callbackEvent) {
            if (callbackEvent) {
                this.showAuthModal(null, callbackEvent.name, callbackEvent.payload)
            } else {
                this.showAuthModal(null)
            }
        },
        selectType (type) {
            this.searchType = type
        },
        selectState (state) {
            this.searchState = state
        },
        showAuthModal (event, busEvent = null, busEventPayload = {}) {
            this.$refs.authModal.show(busEvent, busEventPayload)
        },
        doSearch () {
            const state = { name: 'search' }

            const query = {
                term: this.searchTerm
            }

            if (this.searchType === 'Images' || this.$route.query.type === 'image') {
                query.type = 'image'
            } else if (this.searchType === 'Videos' || this.$route.query.type === 'video') {
                query.type = 'video'
            }

            if (this.searchState !== 'ALL AUS') {
                query.state = this.searchState
            }

            state.query = query

            this.$router.push(state).catch(() => {
            })
        },

        async logout () {
            await this.$router.push({ name: 'welcome' }).catch(() => {
            })

            await this.$store.dispatch('auth/logout')
        }
    }
}
</script>

<style scoped>
.profile-photo {
    width: 2rem;
    height: 2rem;
    margin: -.375rem 0;
}
</style>
